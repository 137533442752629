<template>
  <div class="horizontal-scroll">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "HorizontalScroll",
};
</script>

<style lang="scss" scoped>
@import "/HorizontalScroll";
</style>
